import { Component, ViewChild, ElementRef, Renderer2, AfterViewInit, OnInit, OnDestroy } from '@angular/core';
import { LlRolesService } from '../ll-roles.service';
import { LlInfoRequestsService } from '../ll-info-requests.service';
import { LlModelsService } from '../ll-models.service';
import { LlPermissionsService } from '../ll-permissions.service';

import { jqxPanelComponent } from 'jqwidgets-scripts/jqwidgets-ts/angular_jqxpanel'
import { jqxGridComponent } from 'jqwidgets-scripts/jqwidgets-ts/angular_jqxgrid';
import { jqxPopoverComponent } from "jqwidgets-scripts/jqwidgets-ts/angular_jqxpopover";
//import { fromEvent } from 'rxjs';
//import { runInThisContext } from 'vm';

@Component({
  selector: 'll-info-request-entry',
  templateUrl: './ll-info-request-entry.component.html',
  styleUrls: ['../cssMain.css']
})

export class LlInfoRequestEntryComponent implements OnInit {

  @ViewChild('mainDiv_ll_info_request_entry') mainDiv: ElementRef;
  @ViewChild('formEntryPanel') private SP: jqxPanelComponent;
  ///@ViewChild('globalListingDatagrid') private datagrid: jqxGridComponent;
  @ViewChild('popover_delete') private popover_delete: jqxPopoverComponent;
  @ViewChild('popover_clear') private popover_clear: jqxPopoverComponent;
  @ViewChild('popover_verifyForm') private popover_verifyForm: jqxPopoverComponent;
  @ViewChild('popover_reviewForm') private popover_reviewForm: jqxPopoverComponent;
  @ViewChild('popover_lockForm') private popover_lockForm: jqxPopoverComponent;
  @ViewChild('popover_signForm') private popover_signForm: jqxPopoverComponent;
  //@ViewChild('popover_questionContext') private popover_questionContext: jqxPopoverComponent;

  constructor(
    private LlRolesService: LlRolesService,
    private LlInfoRequestsService: LlInfoRequestsService,
    private LlModelsService: LlModelsService,
    private LlPermissionsService: LlPermissionsService,
    //private RoleQcstagesService: RoleQcstagesService,
    ///private renderer: Renderer2,

  ) { }

  bundle = {
    ac: "",
    showDisplay: false,
    visable: false,
    refresh: false,

    STUDYQUESTIONID: 0,
    QUESTIONSTAGEID: 0,
    SUBJECTVISITFORMID: 0,
    SYSTEMROLEID: 0,
    SUBJECTVISITID: 0,
    LEADLENDERCOMPANYID:0,
    FOLDERNAME: '',
    COMPANYORCONTACT: "",
    circleImg: "",
    FORMNAME: "",
    showHeader: true,
    showObj: true,
    showDate: false,

    questions: [],
    roleQCStages: [],
    recordList: [],
    columns: [],
    type: "",
    showDG: false,
    showPUWindow: false,
    deleteFormsStatus: "none",
    canClearForms: false,
    canOpenTicket: false,
    canVerifyForm: false,
    canReviewForm: false,
    canLockForm: false,
    canSignForm: false,
    showAll: false,
    showQCLable:false,
    LlInfoRequestsService: this.LlInfoRequestsService,
    //PermisionsService: this.PermisionsService,
    ///MessagesService: this.MessagesService,
    //RoleQcstagesService: this.RoleQcstagesService,
    parentW: 0,
    parentH: 0,
    remainingX: 0,
    remainingY: 0,
    remainingW: 0,
    remainingH: 0,
    objW: 0,
    lblY: 0,
    spY: 0,
    spW: 0,
    spH: 0,
    spB: 0,
    objH: 0,
    tabH: 0,

    questionW: 0,
    valueRow01W: 200,

    col01W: 25, //status circle
    col02W: 25, //row counter
    col03W: 0, //question language
    col04W: 300, //data widget
    col05W: 0, //qc boxes
    col03Plus4W: 0, //first Col plus 2nd
    col01X: 0, //status circle
    col02X: 0, //row counter
    col03X: 0, //question language
    col04X: 0, //data widget
    col05X: 0, //qc boxes

  }


  eventSubscription_renewForm: any;

  ngOnDestroy() {
    this.eventSubscription_renewForm.unsubscribe();
  }

  ngOnInit() { }

  ngAfterViewInit() {

    this.eventSubscription_renewForm = this.LlInfoRequestsService.renewForm.subscribe(location => {

      console.log("eventSubscription_renewForm")
    
        //this.bundle.questions = this.bundle.LlInfoRequestsService.getFormQuestions();     


        this.getData("refreshQuestions")

        setTimeout(() => {
          //this.bundle.showObj = true;
          //this.bundle.showDisplay = true;
        });       
      
    });

    this.setVars()

  }

  setVars() {

    ////console.log("setVars()")

    setTimeout(() => {
      ///this.bundle.visable = false;

      //this.bundle.showDisplay = false;
      //this.bundle.showObj = false;
      this.bundle.showPUWindow = true;
      this.bundle.deleteFormsStatus = "none";      
      this.bundle.canClearForms = this.LlPermissionsService.check("clearForms");
      this.bundle.canVerifyForm = this.LlPermissionsService.check("qcverify");
      this.bundle.canReviewForm = this.LlPermissionsService.check("qcreview");
      this.bundle.canLockForm = this.LlPermissionsService.check("qclock");
      this.bundle.canSignForm = this.LlPermissionsService.check("qcsignature");

      if (this.bundle.canVerifyForm
        || this.bundle.canReviewForm
        || this.bundle.canLockForm
        || this.bundle.canSignForm) {
        this.bundle.showQCLable = true;
      }

      this.bundle.parentW = this.mainDiv.nativeElement.parentElement.parentElement.clientWidth;
      this.bundle.parentH = this.mainDiv.nativeElement.parentElement.parentElement.clientHeight;

      this.bundle.spY = 40;
      this.bundle.objW = this.bundle.parentW - 15;
      this.bundle.objH = this.bundle.parentH - this.bundle.spY - 30;
      this.bundle.spB = this.bundle.spY + this.bundle.objH;
      this.bundle.type = 'file'

      this.bundle.questionW = this.bundle.parentW - this.bundle.valueRow01W;

      
                 
      this.getData("fromSetVars")
    });
  }

  getData(action) {
    
    let activeModel = this.LlModelsService.getActiveLoanModel();
    let activeVisitEvent = this.LlInfoRequestsService.getActiveSubjectVisit();
    let activeForm = this.LlInfoRequestsService.getActiveSubjectVisitForm();

    //this.bundle.LEADLENDERCOMPANYID = activeModel.LEADLENDERCOMPANYID;
    //this.bundle.FORMNAME = activeModel.FOLDERNAME + " - " + activeVisitEvent.FOLDERNAME + " - " + activeForm.FOLDERNAME

    if (action == "fromSetVars") {
      this.LlInfoRequestsService.NG_LL_infoReq_subjectQuestion_choices_get(done02, this.bundle);
    }else{            
      this.bundle.questions = this.bundle.LlInfoRequestsService.getFormQuestions();
    }

    function done02(bundle) {
      bundle.LlInfoRequestsService.NG_LL_infoReq_uploads_form_get(done03, bundle);
    }

    function done03(bundle) {
      bundle.LlInfoRequestsService.NG_LL_infoReq_uploads_acceptedExtentions_get(done04, bundle);
    }

    function done04(bundle) {       
      bundle.LlInfoRequestsService.NG_LL_infoReq_subjectQuestions_get(done05, bundle, 'entryForm');
    }

    function done05(questions, bundle) {

      bundle.questions = questions;
     

      //console.log(bundle.questions)

      setTimeout(() => {
        bundle.showObj = true;
        bundle.showDisplay = true;

      });
    }

  }

  noAction() {
    console.log("noAction")
    //needed HTML buttons point to this

    //this.closeAllPopovers() 
  }


  closeAllPopovers() {
    console.log("closeAllPopovers()")

    this.popover_delete.close();
    
    
    
    
    
  }

  clearForm() {
    this.popover_clear.close();
    //this.LlInfoRequestsService.clearForm(done01, this.bundle)

    function done01(bundle) {
      //bundle.VisitsService.triggerRenewVisit()
    }
  }

  verifyForm() {
    this.popover_verifyForm.close();

    //this.LlInfoRequestsService.NG_QCStatus_subjectVisitForm(3);
  }

  reviewForm() {
    this.popover_reviewForm.close();
    ///this.LlInfoRequestsService.NG_QCStatus_subjectVisitForm(13);
  }

  lockForm() {
    this.popover_lockForm.close();
    //this.LlInfoRequestsService.NG_QCStatus_subjectVisitForm(5);
  }

  signForm() {
    this.popover_signForm.close();
    //this.LlInfoRequestsService.NG_QCStatus_subjectVisitForm(6);
  }

  refreshData(bundle) {

    bundle.LlInfoRequestsService.getNew("entry", done02, bundle);

    function done02(dummy, bundle) {
      bundle.VisitsService.sendRefreshSubject();
    }

  }

  myGridOnRowSelect(event: any): void {

    //this.VisitsService.setActiveSubjectVisitId(this.bundle.recordList[event.args.rowindex].SUBJECTVISITID);
    // this.LlInfoRequestsService.setActiveSubjectVisitFormId(this.bundle.recordList[event.args.rowindex].SUBJECTVISITFORMID);
    //this.VisitsService.triggerChangeSubjectVisit(this.bundle.location);
  };

  exportOnClick() {

    //this.RoleListService.getActiveContactRole(done01, this.bundle, this.datagrid)

    function done01(activeRole, bundle, datagrid) {


      var today = new Date();
      //var isoDate = javaDate_to_YYYYMMDD(today)

      //var filename = isoDate+"_"+activeRole.STUDYCODE+"_Pending_"+bundle.activePending;

      //console.log(filename)

      //datagrid.exportdata('xls', filename);

    }
  };






}

