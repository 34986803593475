import { Component, ViewChild, ElementRef, AfterViewInit, OnInit } from '@angular/core';
import { LlInfoRequestsService } from '../ll-info-requests.service';
import { LlPermissionsService } from '../ll-permissions.service';

@Component({
  selector: 'll-info-request-entry-question',
  templateUrl: './ll-info-request-entry-question.component.html',
  styleUrls: ['../cssMain.css']
})
export class LlInfoRequestEntryQuestionComponent {

  //@ViewChild('textAreaReference') private myInput: jqxTextAreaComponent;
  //@ViewChild('inputField') private inputField: ElementRef;
  @ViewChild('metricHeader') metricHeader: ElementRef;


  constructor(
    private LlInfoRequestsService: LlInfoRequestsService,
    private LlPermissionsService: LlPermissionsService,
  ) { }

  bundle = {
    showObj: false,
    showQCCheck: false,
    LlInfoRequestsService: this.LlInfoRequestsService,
    STUDYQUESTIONID: 0,
    QUESTIONINPUTTYPE: '',
    QUESTIONNAME: '',
    QUESTIONINSTRUCTIONS:'',
    COUNT: "",
    CIRCLEIMG_QUESTION: "",
    parentW:0,
    objW: 0,
    col01W: 30, //QC Stages
    col02W: 70, //status circle and row counter
    col03W: 0, //question language
  }



  ngOnInit() { }

  eventSubscription_renewQuestion: any;

  ngOnDestroy() {
    this.eventSubscription_renewQuestion.unsubscribe();
  }

  ngAfterViewInit() {

    this.eventSubscription_renewQuestion = this.LlInfoRequestsService.renewQuestion.subscribe(STUDYQUESTIONID => {

      if (this.bundle.STUDYQUESTIONID == STUDYQUESTIONID) {
        this.setVars();
      }

    });

    this.setVars();

  }

  setVars() {

    this.bundle.STUDYQUESTIONID = this.metricHeader.nativeElement.parentElement.id;
    let record = this.LlInfoRequestsService.getSelectStudyQuestion(this.bundle.STUDYQUESTIONID);

    //console.log(record)

    setTimeout(() => {

      this.bundle.objW = this.metricHeader.nativeElement.parentElement.parentElement.clientWidth;
      this.bundle.col03W = this.bundle.objW - this.bundle.col01W - this.bundle.col02W - 40; 

      this.bundle.COUNT = record.COUNT;
      this.bundle.CIRCLEIMG_QUESTION = record.CIRCLEIMG_QUESTION;
      this.bundle.QUESTIONINPUTTYPE = record.QUESTIONINPUTTYPE;
      this.bundle.QUESTIONNAME = record.QUESTIONNAME;
      this.bundle.QUESTIONINSTRUCTIONS = record.QUESTIONINSTRUCTIONS;

      //questionStageId	questionStageName
      //1	Not Entered
      //2	Entered
      //3	Verified
      //5	Reviewed
      //6	Locked
      //20	Signed

      //console.log("record.QUESTIONSTAGEID: " + record.QUESTIONSTAGEID)
      //console.log(this.LlPermissionsService.check("qcverify"))

      if (record.QUESTIONSTAGEID == 2 && record.QUESTIONREQUIRED == 'R' && this.LlPermissionsService.check("qcverify")){
        this.bundle.showQCCheck = true;
      }

      if (record.QUESTIONSTAGEID == 3 && record.QUESTIONREQUIRED == 'R' && this.LlPermissionsService.check("qcreview")) {
        this.bundle.showQCCheck = true;
      }

      if (record.QUESTIONSTAGEID == 5 && record.QUESTIONREQUIRED == 'R' && record.QUESTIONREQUIRED && this.LlPermissionsService.check("qclock")) {
        this.bundle.showQCCheck = true;
      }

      if (record.QUESTIONSTAGEID == 6 && record.QUESTIONREQUIRED == 'R' && this.LlPermissionsService.check("qcsignature")) {
        this.bundle.showQCCheck = true;
      }

    });


  }

  stageChange(ACTION) {

    this.LlInfoRequestsService.setActiveStudyQuestionId(this.bundle.STUDYQUESTIONID);
    this.LlInfoRequestsService.NG_LL_infoReq_subjectQuestion_status_edit(ACTION);

    
  }



}

