import { Component, ViewChild, ElementRef, AfterViewInit, OnInit } from '@angular/core';
///import { create } from 'domain';
import { LlCompaniesService } from '../ll-companies.service';

import { LlInfoRequestsService } from '../ll-info-requests.service';
import { LlPermissionsService } from '../ll-permissions.service';
import { jqxPopoverComponent } from "jqwidgets-scripts/jqwidgets-ts/angular_jqxpopover";

import { removeTextChars } from "../utility";
import { formatDecimalPlaces } from "../utility";
import { removeNumberCommas } from "../utility";

@Component({
  selector: 'll-company-info',
  templateUrl: './ll-company-info.component.html',
  styleUrls: ['../cssMain.css']
})

export class LlCompanyInfoComponent implements OnInit {

  @ViewChild('mainDiv_ll_company_info') mainDiv: ElementRef;
  @ViewChild('popover_message') private popover_message: jqxPopoverComponent;

  constructor(
    private LlCompaniesService: LlCompaniesService,
    private LlInfoRequestsService: LlInfoRequestsService,
    private LlPermissionsService: LlPermissionsService,
  ) {}

  company = {
    COMPANYID:0,
    COMPANYSTATUSACTIVEINACTIVE: '',
    COMPANYNAME: '',
    COMPANYABREV: '',
    COMPANYSTATE: '',
    COMPANYCITY:'',
    COMPANYCOUNTRY: '',
    COMPANYEMAILADDRESS:'',
    SYSTEMCOMPANYTYPEID: 0,
    TAXONOMYID_SYS: 0,
    VARIABLEFIELD01:'',
    VARIABLEFIELD02: '',
    VARIABLEFIELD03: '',
    VARIABLEFIELD04: '',
    VARIABLEFIELD05: '',
    VARIABLEFIELD06: '',
    VARIABLEFIELD07: '',
    VARIABLEFIELD08: '',
  }

  bundle = {
    showObj: false,
    canAdd: false,
    canEdit: false,
    disabled: true,
    location_CMS_search_mode:false,
    attentionMsg: 'No Message. This notice is in error',
    userMsg:'',
    SUBJECTVISITFORMID: 0,
    COMPANYID:0,
    variableFields: [],
    companyTypes: [],
    companyTaxonomy: [],
    companyTypeChoices: [],
    companyTaxonomyChoices: [],
    states: [],
    countries: [],
    companyStagesAll: [],
    companyStatusActiveInactiveChoices: [],
    questions:[],
    companyTypeSelectIndex: 99999,
    showRqrMsg: false,
    parentW: 0,
    parentH: 0,
    remainingX: 0,
    remainingY: 0,
    remainingW: 0,
    remainingH: 0,
    objH: 0,
    objW: 0,
    headerY: 10,
    headerW: 0,
    headerH: 200,
    bodyH: 0,
    col01W: 200,
    col02W: 0,
    LlCompaniesService: this.LlCompaniesService,
    LlInfoRequestsService: this.LlInfoRequestsService,
    company: this.company,
    SYSTEMCOMPANYTYPEID: 0,
    stateIndex: -1,
    countryIndex: -1,
    statusIndex: -1,
    companyTaxonomyIndex: -1,
  }

  ngOnInit() { }
  ngOnDestroy() { }


  ngAfterViewInit(): void {
    this.setVars();
  }
  
  setVars() {

    this.bundle.showObj = false;
    this.bundle.canEdit = this.LlPermissionsService.check("addEditCompany");

    this.bundle.disabled = true;

    if (this.bundle.canEdit) {
      this.bundle.disabled = false;
    }

    setTimeout(() => {
      
      this.bundle.parentW = this.mainDiv.nativeElement.parentElement.parentElement.clientWidth;
      this.bundle.parentH = this.mainDiv.nativeElement.parentElement.parentElement.clientHeight;

      this.bundle.objW = this.bundle.parentW ;
      this.bundle.objH = this.bundle.parentH - 27;

      this.bundle.col02W = this.bundle.parentW  - this.bundle.col01W - 70;

      if (this.bundle.col02W > 400) {
        this.bundle.col02W = 400;
      }

      this.getData(this.bundle)
    });
  }

  getData(bundle) {

    bundle.showRqrMsg = false;

    bundle.COMPANYID = bundle.LlCompaniesService.getActiveCompanyId();

    if (bundle.COMPANYID != 0) {
      bundle.LlCompaniesService.NG_LL_company_get(done01, bundle);
    }

    function done01(company, bundle) {

      bundle.company = company[0];

      bundle.SUBJECTVISITFORMID = bundle.company.SUBJECTVISITFORMID;

      bundle.LlInfoRequestsService.setActiveSubjectVisitFormId(bundle.company.SUBJECTVISITFORMID);

      bundle.SYSTEMCOMPANYTYPEID = bundle.company.SYSTEMCOMPANYTYPEID;

//console.log(bundle.company)

      setTimeout(() => {        

        ///create state Array
        bundle.states =
          ['AL', 'AK', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'DC', 'FL', 'GA', 'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME',
            'MD', 'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ', 'NM', 'NY', 'NC', 'ND', 'OH', 'OK', 'OR', 'PA', 'RI',
            'SC', 'SD', 'TN', 'TX', 'UT', 'VT', 'VA', 'WA', 'WV', 'WI', 'WY',];

        for (let i = 0; i < bundle.states.length; i++) {
          if (bundle.states[i] == bundle.company.COMPANYSTATE) {
            bundle.stateIndex = i;
          }

        }

        //create country array
        bundle.countries =
          ['United States', 'Afghanistan', 'Albania', 'Algeria', 'Andorra', 'Angola', 'Antigua and Barbuda', 'Argentina', 'Armenia', 'Australia',
            'Austria', 'Azerbaijan', 'The Bahamas', 'Bahrain', 'Bangladesh', 'Barbados', 'Belarus', 'Belgium', 'Belize', 'Benin', 'Bhutan', 'Bolivia',
            'Bosnia and Herzegovina', 'Botswana', 'Brazil', 'Brunei', 'Bulgaria', 'Burkina Faso', 'Burundi', 'Cambodia', 'Cameroon', 'Canada', 'Cape Verde',
            'Central African Republic', 'Chad', 'Chile', 'China', 'Colombia', 'Comoros', 'Congo, Republic of the', 'Congo, Democratic Republic of the', 'Costa Rica',
            'Cote d Ivoire', 'Croatia', 'Cuba', 'Cyprus', 'Czech Republic', 'Denmark', 'Djibouti', 'Dominica', 'Dominican Republic', 'East Timor (Timor-Leste)',
            'Ecuador', 'Egypt', 'El Salvador', 'Equatorial Guinea', 'Eritrea', 'Estonia', 'Ethiopia', 'Fiji', 'Finland', 'France', 'Gabon', 'The Gambia', 'Georgia',
            'Germany', 'Ghana', 'Greece', 'Grenada', 'Guatemala', 'Guinea', 'Guinea-Bissau', 'Guyana', 'Haiti', 'Honduras', 'Hungary', 'Iceland', 'India', 'Indonesia',
            'Iran', 'Iraq', 'Ireland', 'Israel', 'Italy', 'Jamaica', 'Japan', 'Jordan', 'Kazakhstan', 'Kenya', 'Kiribati', 'Korea, North', 'Korea, South', 'Kosovo',
            'Kuwait', 'Kyrgyzstan', 'Laos', 'Latvia', 'Lebanon', 'Lesotho', 'Liberia', 'Libya', 'Liechtenstein', 'Lithuania', 'Luxembourg', 'Macedonia', 'Madagascar',
            'Malawi', 'Malaysia', 'Maldives', 'Mali', 'Malta', 'Marshall Islands', 'Mauritania', 'Mauritius', 'Mexico', 'Micronesia, Federated States of', 'Moldova',
            'Monaco', 'Mongolia', 'Montenegro', 'Morocco', 'Mozambique', 'Myanmar (Burma)', 'Namibia', 'Nauru', 'Nepal', 'Netherlands', 'New Zealand', 'Nicaragua',
            'Niger', 'Nigeria', 'Norway', 'Oman', 'Pakistan', 'Palau', 'Panama', 'Papua New Guinea', 'Paraguay', 'Peru', 'Philippines', 'Poland', 'Portugal', 'Qatar',
            'Romania', 'Russia', 'Rwanda', 'Saint Kitts and Nevis', 'Saint Lucia', 'Saint Vincent and the Grenadines', 'Samoa', 'San Marino', 'Sao Tome and Principe',
            'Saudi Arabia', 'Senegal', 'Serbia', 'Seychelles', 'Sierra Leone', 'Singapore', 'Slovakia', 'Slovenia', 'Solomon Islands', 'Somalia', 'South Africa',
            'South Sudan', 'Spain', 'Sri Lanka', 'Sudan', 'Suriname', 'Swaziland', 'Sweden', 'Switzerland', 'Syria', 'Taiwan', 'Tajikistan', 'Tanzania', 'Thailand',
            'Togo', 'Tonga', 'Trinidad and Tobago', 'Tunisia', 'Turkey', 'Turkmenistan', 'Tuvalu', 'Uganda', 'Ukraine', 'United Arab Emirates', 'United Kingdom',
            'United States', 'Uruguay', 'Uzbekistan', 'Vanuatu', 'Vatican City (Holy See)', 'Venezuela', 'Vietnam', 'Yemen', 'Zambia', 'Zimbabwe',];

        for (let j = 0; j < bundle.countries.length; j++) {
          if (bundle.countries[j] == bundle.company.COMPANYCOUNTRY) {

            bundle.countryIndex = j;
          }

        }

        ///create status array
        bundle.companyStatusActiveInactiveChoices = ['Active', 'Inactive']

        for (let k = 0; k < bundle.companyStatusActiveInactiveChoices.length; k++) {
          if (bundle.companyStatusActiveInactiveChoices[k] == bundle.company.COMPANYSTATUSACTIVEINACTIVE) {

            bundle.statusIndex = k;

          }
        }

        
        bundle.LlCompaniesService.NG_LL_company_types_get(done03, bundle);
      });
    }

    function done02(arr, bundle) {
      bundle.variableFields = arr

      for (let i = 0; i < arr.length; i++) {
        if (arr[i].QUESTIONINPUTGROUP.toLowerCase() == "number") {

          if (arr[i].VARIABLEFIELD == "variableField01") {
            bundle.company.VARIABLEFIELD01 = formatDecimalPlaces(bundle.company.VARIABLEFIELD01, 0)
          }

          if (arr[i].VARIABLEFIELD == "variableField02") {
            bundle.company.VARIABLEFIELD02 = formatDecimalPlaces(bundle.company.VARIABLEFIELD02, 0)
          }

          if (arr[i].VARIABLEFIELD == "variableField03") {
            bundle.company.VARIABLEFIELD03 = formatDecimalPlaces(bundle.company.VARIABLEFIELD03, 0)
          }

          if (arr[i].VARIABLEFIELD == "variableField04") {
            bundle.company.VARIABLEFIELD04 = formatDecimalPlaces(bundle.company.VARIABLEFIELD04, 0)
          }
          if (arr[i].VARIABLEFIELD == "variableField05") {
            bundle.company.VARIABLEFIELD05 = formatDecimalPlaces(bundle.company.VARIABLEFIELD05, 0)
          }

          if (arr[i].VARIABLEFIELD == "variableField06") {
            bundle.company.VARIABLEFIELD06 = formatDecimalPlaces(bundle.company.VARIABLEFIELD06, 0)
          }

          if (arr[i].VARIABLEFIELD == "variableField07") {
            bundle.company.VARIABLEFIELD07 = formatDecimalPlaces(bundle.company.VARIABLEFIELD07, 0)
          }

          if (arr[i].VARIABLEFIELD == "variableField08") {
            bundle.company.VARIABLEFIELD08 = formatDecimalPlaces(bundle.company.VARIABLEFIELD08, 0)
          }

        }

      }

      bundle.LlCompaniesService.NG_quest_build_companyTypes_get(done03, bundle);
      
    }

    function done03(arr, bundle) {
      bundle.companyTypes = arr;

      //console.log(bundle)

      let listString01 = []

      for (let i = 0; i < arr.length; i++) {
        listString01.push(arr[i].COMPANYTYPENAME);

        if (arr[i].COMPANYTYPENAME == bundle.company.COMPANYTYPENAME) {
          bundle.companyTypeSelectIndex = i
        }
      }

      setTimeout(() => {
        bundle.companyTypeChoices = listString01;

        bundle.LlCompaniesService.NG_LL_company_taxonomyCodes_get(done04, bundle);
      });


    }

    function done04(arr, bundle) {
      bundle.companyTaxonomy = arr;

      let listString02 = []

      for (let i = 0; i < arr.length; i++) {
        listString02.push(arr[i].NAME);

        if (arr[i].TAXONOMYID_SYS == bundle.company.TAXONOMYID_SYS) {
          bundle.companyTaxonomyIndex = i
        }
      }

      setTimeout(() => {
        bundle.companyTaxonomyChoices = listString02;

        if (bundle.company.SUBJECTVISITFORMID != 0) {
          bundle.LlInfoRequestsService.NG_LL_infoReq_subjectQuestions_get(done05, bundle, 'company');
        } else {
          bundle.showObj = true;

        }
        

      });
    }

    function done05(arr, bundle) {

      //console.log(arr)

      bundle.questions = arr;
      bundle.showObj = true;
    }
  }

  selectState(event: any) {
    this.bundle.company.COMPANYSTATE = this.bundle.states[event.args.index];
    this.editCompany();
  }

  selectCountry(event: any) {
    this.bundle.company.COMPANYCOUNTRY = this.bundle.countries[event.args.index];
    this.editCompany();
  }

  selectType(event: any): void {

      this.bundle.SYSTEMCOMPANYTYPEID = this.bundle.companyTypes[event.args.index].SYSTEMCOMPANYTYPEID;
    this.bundle.company.SYSTEMCOMPANYTYPEID = this.bundle.SYSTEMCOMPANYTYPEID
   ////this.editCompany();

    this.bundle.showRqrMsg = true;
  };

  selectTaxonomy(event: any): void {
    this.bundle.company.TAXONOMYID_SYS = this.bundle.companyTaxonomy[event.args.index].TAXONOMYID_SYS;
    this.editCompany();
  };

  selectSTUDYCOMPANYSTATUS(event: any): void {
    this.bundle.company.COMPANYSTATUSACTIVEINACTIVE = this.bundle.companyStatusActiveInactiveChoices[event.args.index];
    this.editCompany();
  };

  abrevCharLimit(event) {

  }

  noAction() {
    //needed HTML buttons point to this 
  }

  trimDecimalPlaces(val, places) {
    return val
  }

  onKeyDown(event) {

    if (event.keyCode == 9 || event.keyCode == 13) { ///Tab || Enter
      this.editCompany();
    }

  }

  editCompany() {

    //console.log(this.bundle.company)

    if (this.bundle.company.COMPANYNAME != ""
      && this.bundle.company.COMPANYABREV != ""
      && this.bundle.company.SYSTEMCOMPANYTYPEID != 0) {

      this.LlCompaniesService.NG_LL_company_edit(this.getData, this.bundle, this.bundle.company);

      
    }
  }

  openMsg(message) {
    this.bundle.attentionMsg = message;
    this.popover_message.open();
  }

};
